import React from "react"
import "./GymSticker.scss"
import suppliersBY from "./images/suppliersBY.jpg"
import suppliersLT from "./images/suppliersLT.png"
import {checkIsAllsports} from "../../../domain_utils"
import {useTranslation} from "react-i18next"

function GymSticker({ language }) {
  const { t } = useTranslation()
  const suppliers = checkIsAllsports(language) ? suppliersBY : suppliersLT
  return (
    <section className="gym-sticker">
      <div className="title">{ t('app.supplier.sticker.title') }</div>
      <img
        src={suppliers}
        alt={ t('app.supplier.sticker.instruction_alt') }
      />
    </section>
  )
}

export default GymSticker
